
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'Overlay',
  computed: {
    ...mapGetters({
      isActive: 'overlay/isActive',
      isMenu: 'overlay/isMenu',
      isMenuShow: 'overlay/isMenuShow',
      customZIndex: 'overlay/customZIndex',
      customTop: 'overlay/customTop'
    })
  },
  methods: {
    ...mapMutations({
      hideOverlay: 'overlay/hideOverlay',
      unsetFullMode: 'overlay/unsetFullMode'
    }),
    subscriptionMoreInfoClose() {
      this.unsetFullMode()
      this.hideOverlay()
    }
  },
  watch: {
    isActive() {
      // const body = document.querySelector('body')
      // if (this.isActive) {
      //   body.style.overflow = 'hidden'
      //   return
      // }
      // body.style.overflow = 'visible'
    }
  },
}
